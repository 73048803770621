import { createAction, ActionsUnion } from "../actionHelper";
import { ParsedParams, parseQueryString, parseWithDefaults } from "@lexdania/components";

// Action Constants
const SET_PARAMS = "SET_PARAMS";
const TOGGLE_GRID_VIEW_ACTIVE = "TOGGLE_GRID_VIEW_ACTIVE";

// Action creators
const setParams = (params: string) => createAction(SET_PARAMS, params);
const toggleGridViewActive = () => createAction(TOGGLE_GRID_VIEW_ACTIVE);

const actions = { setParams, toggleGridViewActive };
type DocumentSearchActions = ActionsUnion<typeof actions>;

export type DocumentSearchParams = {
  page: number; //Active page
  ps: number; //Active page size
  o: number; //Selected order by value
  d: boolean; //Is sort order ascending
  yh: number; //Year high/end
  yl: number; //Year low/start
  r: string[]; //Ressort Ids
  dt: string[]; //Document types
  f: string[]; //FOBRessort Ids (myndigheder)
  t: string[]; //SearchTerms
  dnr: string; //Document number range
  h: boolean; //Are historical documents included
  mth: string; //Month (Signature date)
  day: string; //Day (Signature date)
  ppm: string; //Publication Media
  lr: string[]; //lawregistry filter
  ce: string; //Celex number
  pttl: string[]; //Populærtitler
  ttl: string[]; //Titler
  so: string[]; //Stikord
  dl: string; //Date low (Offentliggørelsesdato)
};

export interface IDocumentSearchState {
  params: string;
  paramsParsed: ParsedParams;
  parseWithDefaults: (defaults: DocumentSearchParams) => DocumentSearchParams;
  isGridViewActive: boolean;
}

// Action Exports
export interface IDocumentSearchActions {
  setParams: (params: string) => void;
  toggleGridViewActive: () => void;
}

export const DocumentSearchActions: IDocumentSearchActions = {
  setParams,
  toggleGridViewActive,
};

const defaultState: IDocumentSearchState = {
  params: "",
  paramsParsed: {},
  parseWithDefaults: defaults => parseWithDefaults({}, defaults), // !Important to keep this as it does a spread of the "empty marker arrays" EmptyNumberArray and EmptyNumberArray,
  isGridViewActive: false,
};

export default (state: IDocumentSearchState = defaultState, action: DocumentSearchActions): IDocumentSearchState => {
  switch (action.type) {
    case SET_PARAMS: {
      const params = action.payload;
      const parsed: ParsedParams = parseQueryString(params);
      return {
        ...state,
        params: params,
        paramsParsed: parsed,
        parseWithDefaults: defaults => parseWithDefaults(parsed, defaults),
      };
    }
    case TOGGLE_GRID_VIEW_ACTIVE: {
      return {
        ...state,
        isGridViewActive: !state.isGridViewActive,
      };
    }

    default:
      return state;
  }
};
