import { createAction, ActionsUnion } from "../actionHelper";
import { IDocumentDetails, IDocumentDetailsState } from "../../models/IDocumentDetails";
import { apiGET, apiPOST } from "../api";
import { AppState } from "..";
import { EliRequest, buildEliRelativeUrl, hasEliRequestChanged } from "../../eli-routing";
import { Dispatch } from "redux";
import { IDocumentMetadata } from "../../models/IDocumentMetadata";
import { FooterReducerActions } from "../footer/footerReducer";

// Action Constants
enum DocumentDetailsActionEnum {
  RECEIVE_DOCUMENT_DETAILS = "RECEIVE_DOCUMENT_DETAILS",
  RECEIVE_DOCUMENT_ELI_METADATA = "RECEIVE_DOCUMENT_ELI_METADATA",
  RECEIVE_SCHEMA_ORG_METADATA = "RECEIVE_SCHEMA_ORG_METADATA",
  REQUEST_DOCUMENT_DETAILS = "REQUEST_DOCUMENT_DETAILS",
}

// Action creators
const receiveDocumentDetails = (documents: IDocumentDetails[]) => createAction(DocumentDetailsActionEnum.RECEIVE_DOCUMENT_DETAILS, documents);
const receiveDocumentEliMetadata = (eliMetaData: object[]) => createAction(DocumentDetailsActionEnum.RECEIVE_DOCUMENT_ELI_METADATA, eliMetaData);
const receiveDocumentSchemaOrgMetadata = (documentMetadata: IDocumentMetadata) =>
  createAction(DocumentDetailsActionEnum.RECEIVE_SCHEMA_ORG_METADATA, documentMetadata);
const requestDocumentDetails = () => createAction(DocumentDetailsActionEnum.REQUEST_DOCUMENT_DETAILS);

const actions = {
  receiveDocumentDetails,
  receiveDocumentMetadata: receiveDocumentEliMetadata,
  receiveSchemaOrgMetadata: receiveDocumentSchemaOrgMetadata,
  requestDocumentDetails: requestDocumentDetails,
};
type DocumentDetailsActions = ActionsUnion<typeof actions>;

const getDocumentDetailsByEliRequest = (eliRequest: EliRequest, isRawHtml: boolean) => async (
  dispatch: Dispatch<DocumentDetailsActions>,
  getState: () => AppState
) => {
  const { documentDetails } = getState();

  if (!documentDetails.documents[0].eliRequest || hasEliRequestChanged(documentDetails.documents[0].eliRequest, eliRequest)) {
    dispatch(requestDocumentDetails());
    const eliUrl = buildEliRelativeUrl(eliRequest);
    FooterReducerActions.hideFooter();
    const documents = await getEliDocumentDetails(dispatch, eliRequest, eliUrl, isRawHtml);
    FooterReducerActions.showFooter();
    if (documents.length === 1 && documents[0].id !== -1) {
      const eliPromise = getEliMetaData(dispatch, eliUrl);
      await eliPromise;

      const getSchemaOrgMetaDataPromise = getSchemaOrgMetaData(dispatch, documents[0].accessionNumber);
      await eliPromise;
      await getSchemaOrgMetaDataPromise;
    }
  }
};

const getEliDocumentDetails = async (
  dispatch: Dispatch<DocumentDetailsActions>,
  request: EliRequest,
  eliUrl: string,
  isRawHtml: boolean
): Promise<IDocumentDetails[]> => {
  const pathName = "api/document/" + eliUrl;
  const result = await apiPOST<IDocumentDetails[]>(pathName, { isRawHtml });
  result.forEach(doc => (doc.eliRequest = request));
  dispatch(receiveDocumentDetails(result));
  return result;
};

const getEliMetaData = async (dispatch: Dispatch<DocumentDetailsActions>, eliUrl: string) => {
  const eliMetaDataUrl = eliUrl + ".rdfa";
  const eliMetaData = await apiGET<object[]>(eliMetaDataUrl);
  dispatch(receiveDocumentEliMetadata(eliMetaData));
};

const getSchemaOrgMetaData = async (dispatch: Dispatch<DocumentDetailsActions>, accessionNumber: string) => {
  const schemaOrgMetadata = await apiGET<IDocumentMetadata>("api/document/metadata/" + accessionNumber);
  dispatch(receiveDocumentSchemaOrgMetadata(schemaOrgMetadata));
};

// Action exports
export interface IDocumentDetailsActions {
  getDocumentDetailsByEliRequest: (eliRequest: EliRequest, isRawHtml: boolean) => void;
}

export const DocumentDetailsActions: IDocumentDetailsActions = {
  getDocumentDetailsByEliRequest,
};

const defaultState: IDocumentDetailsState = {
  documents: [
    {
      id: -1,
      accessionNumber: "",
      title: "",
      documentTypeId: "",
      isSagsforlobPeriodValid: false,
      shortName: "",
      documentHtml: "",
      ressort: "",
      popularTitle: "",
      editorialNotes: [],
      alternativeMedia: [],
      documentReferenceGroups: [],
      euReferenceGroups: [],
      isHistorical: false,
      caseHistoryReferenceGroup: [],
      pdfLink: { isPdfAvailable: false },
      metadata: [],
      hasFobTags: false,
      isReprint: false,
      documentFobTagGroup: [],
      geografiskDaekningId: -1,
      retsinfoKlassifikationId: -1,
    },
  ],
  eliMetaData: [],
  schemaOrgMetadata: "",
  canonicalUri: "",
  isLoading: false,
};

export interface IDocumentContentProps {
  shortName: string;
  restylingRootId: string;
  documentHtml: string;
  editorialNotes: string[];
  reprintNotes: string[];
  isHistorical: boolean;
}

export default (state: IDocumentDetailsState = defaultState, action: DocumentDetailsActions): IDocumentDetailsState => {
  switch (action.type) {
    case DocumentDetailsActionEnum.REQUEST_DOCUMENT_DETAILS: {
      return {
        ...state,
        documents: defaultState.documents,
        eliMetaData: defaultState.eliMetaData,
        isLoading: true,
      };
    }

    case DocumentDetailsActionEnum.RECEIVE_DOCUMENT_DETAILS: {
      return {
        ...state,
        documents: action.payload,
        isLoading: false,
      };
    }

    case DocumentDetailsActionEnum.RECEIVE_DOCUMENT_ELI_METADATA: {
      return {
        ...state,
        eliMetaData: action.payload,
      };
    }

    case DocumentDetailsActionEnum.RECEIVE_SCHEMA_ORG_METADATA: {
      return {
        ...state,
        schemaOrgMetadata: action.payload.schemaOrgMetadata,
        canonicalUri: action.payload.canonicalUri,
      };
    }

    default:
      return state;
  }
};
