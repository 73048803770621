import axios, { AxiosError } from "axios";

export const setupApi = (responseErrorCallback: (errorCode: number) => void) => {
  const setupAxiosGlobalRequest = () => {
    axios.interceptors.request.use(
      config => {
        // Do something before request is sent

        config.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          pragma: "no-cache",
          "cache-control": "no-cache",
        };

        return config;
      },
      (error: AxiosError) => {
        console.log(error.code + " " + error.message + " " + error.response);
      }
    );
  };

  const setupAxiosGlobalResponse = (responseErrorCallback: (errorCode: number) => void) => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      (error: AxiosError) => {
        if (error !== undefined && error.response !== undefined) {
          responseErrorCallback(error.response.status);
        }

        return Promise.reject(error);
      }
    );
  };

  setupAxiosGlobalRequest();
  setupAxiosGlobalResponse(responseErrorCallback);
};

export const apiGET = <T>(url: string, options?: any): Promise<T> => axios.get(url, options).then<T>(r => r.data);
export const apiPOST = <T>(url: string, body?: any, options?: any) => axios.post(url, body, options).then<T>(r => r.data);
export const apiPUT = <T>(url: string, body: any) => axios.put(url, body).then<T>(r => r.data);
export const apiDELETE = <T>(url: string, body?: any) => axios.delete(url, body).then<T>(r => r.data);
