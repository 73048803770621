import React from "react";
import { Footer } from "@lexdania/components";
import { IFooterReducerActions, FooterReducerActions, IExternalLinks } from "./../store/footer/footerReducer";
import { AppState } from "../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

interface IFooterContainerProps extends IFooterReducerActions {
  externalLinks: IExternalLinks;
  isFooterVisible: boolean;
}

class FooterContainer extends React.Component<IFooterContainerProps, any> {
  public componentDidMount(): void {
    this.props.getExternalLinks();
  }

  public render() {
    const wasLink = this.props.externalLinks.wasLink;
    const bottomLinks = [
      { text: "Om Retsinformation", url: "/about" },
      { text: "Kontakt", url: "/contact" },
      { text: "FAQ", url: "/faq" },
      { text: "Om ELI", url: "/eli" },
      { text: "API", url: "/api" },
      { text: wasLink.text, url: wasLink.url, isExternal: true },
    ];
    const listLinks = this.props.externalLinks.listLinks;
    return (
      this.props.isFooterVisible && (
        <Footer aboutHeader="Om" bottomLinks={bottomLinks} externalLinks={listLinks}>
          Retsinformation.dk er indgangen til det fælles statslige retsinformationssystem, der giver adgang til alle gældende love, bekendtgørelser og
          cirkulærer m.v. Der er også adgang til Folketingets dokumenter og beretninger fra Folketingets Ombudsmand.
          <br />
          På retsinformation.dk anvendes der ikke cookies.
        </Footer>
      )
    );
  }
}

export default connect(
  ({ footer }: AppState, ownProps: IFooterContainerProps) => ({
    ...ownProps,
    ...{ externalLinks: footer.externalLinks, isFooterVisible: footer.isFooterVisible },
  }),
  dispatch =>
    bindActionCreators(
      {
        ...FooterReducerActions,
      },
      dispatch
    )
)(FooterContainer);
