import * as React from "react";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import Layout from "./components/Layout";
import { multiDocumentEliTemplates, singleDocumentEliTemplates, singleDocumentEliTemplatesRawHtml } from "./eli-routing";
const Home = lazy(() => import("./components/Home"));
const EliRoute = lazy(() => import("./containers/eli/EliRoute"));
const EliPartialRedirect = lazy(() => import("./containers/eli/EliPartialRedirect"));
const DocumentsContainer = lazy(() => import("./containers/documents/DocumentsContainer"));
const DocumentDetailsContainer = lazy(() => import("./containers/documentDetail/DocumentDetailsContainer"));
const NotFoundContainer = lazy(() => import("./containers/error/NotFoundContainer"));
const GlobalErrorContainer = lazy(() => import("./containers/error/GlobalErrorContainer"));
const ExtremeSearchContainer = lazy(() => import("./containers/extremesearch/ExtremeSearchContainer"));
const EliContainer = lazy(() => import("./containers/eli/EliContainer"));
const TextContainer = lazy(() => import("./containers/text/TextContainer"));
import { TextType } from "./models/TextType";
const FaqContainer = lazy(() => import("./containers/faq/FaqContainer"));
const OffentligGuideContainer = lazy(() => import("./containers/offentligGuides/OffentligGuidesContainer"));

export const routes = (
  <>
    <Suspense fallback={<div></div>}>
      <Switch>
        {singleDocumentEliTemplatesRawHtml.map(([template, kind]) => (
          <EliRoute path={template} exact kind={kind} key={template} component={DocumentDetailsContainer} />
        ))}

        <Route path="*">
          <Layout>
            <Suspense fallback={<div></div>}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/documents" component={DocumentsContainer} />
                <Route path="/error" component={GlobalErrorContainer} />

                {singleDocumentEliTemplates.map(([template, kind]) => (
                  <EliRoute path={template} exact kind={kind} key={template} component={DocumentDetailsContainer} />
                ))}

                {multiDocumentEliTemplates.map(([template, kind]) => (
                  <EliRoute path={template} exact kind={kind} key={template} component={EliPartialRedirect} />
                ))}

                <Route path="/eli" component={EliContainer} />

                <Route path="/about">
                  <TextContainer type={TextType.About} title="Retsinformation | Om" />
                </Route>
                <Route path="/contact">
                  <TextContainer type={TextType.Contact} title="Retsinformation | Kontakt" />
                </Route>

                <Route path="/extremesearch" component={ExtremeSearchContainer} />
                <Route path="/faq" component={FaqContainer} />
                <Route exact path="/api">
                  <TextContainer type={TextType.Api} title="Retsinformation | API" />
                </Route>
                <Route path="/offentlig/vejledninger" component={OffentligGuideContainer} />
                <Route path="/notfound" component={NotFoundContainer} />
                <Route path="*" component={NotFoundContainer} />
              </Switch>
            </Suspense>
          </Layout>
        </Route>
      </Switch>
    </Suspense>
  </>
);
