import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "..";
import { Dispatch } from "redux";
import { EliInfoLanguage } from "./eliDomain";

// Action Constants
enum EliListsActionType {
  RECEIVE_ELI_LISTS = "RECEIVE_ELI_LISTS",
}

// Action creators
const receiveEliLists = (lists: IEliListsState) => createAction(EliListsActionType.RECEIVE_ELI_LISTS, lists);

const actionCreatorsMap = { receiveEliLists };
type EliListsActionsUnion = ActionsUnion<typeof actionCreatorsMap>;

const getEliLists = () => async (dispatch: Dispatch<EliListsActionsUnion>, getState: () => AppState) => {
  const { eliLists } = getState();
  if (eliLists === defaultState) {
    const lists = await apiGET<IEliListsState>("api/eli/named-authority-lists");
    dispatch(receiveEliLists(lists));
  }
};

// Action exports
export interface IEliListsActions {
  getEliLists: () => void;
}

export const EliListsActions: IEliListsActions = {
  getEliLists,
};

export interface IEliListsState {
  [P: string]: IEliProperty;
}

export interface IEliProperty {
  name: string;
  description: string;
  values: IEliPropertyValue[];
}

export interface IEliPropertyValue {
  authorityCode: string;
  name: string;
  //namespace: string; TODO ... should we populate this and is it of use?!?
  description: Record<EliInfoLanguage, string>;
}

const defaultState: IEliListsState = {};

export default (state: IEliListsState = defaultState, action: EliListsActionsUnion): IEliListsState => {
  const type = action.type;
  switch (type) {
    case EliListsActionType.RECEIVE_ELI_LISTS:
      return action.payload;
    default:
      return state;
  }
};
