type NumericString = string;

interface IEliAccnAliasRequest {
  kind: "accn_alias";
  accn: string;
}

interface IEliFtCanonicalRequest {
  kind: "ft_canonical";
  accn: string;
}

interface IEliCstCanonicalRequest {
  kind: "cst_canonical";
  pubMedia: string;
  year: NumericString;
  number: string;
}

interface IEliRetsinfoClassificationAliasRequest {
  kind: "classification_alias";
  classification: string;
  docType: string;
  year: NumericString;
  month: NumericString;
  day: NumericString;
  number: string;
}

interface IEliCstPartialRequest {
  kind: "cst_partial";
  pubMedia: string;
  year: NumericString;
}

interface IEliRetsinfoClassificationPartialRequest {
  kind: "classification_partial";
  classification: string;
  docType: string;
  year: NumericString;
  month?: NumericString;
  day?: NumericString;
}

interface IEliEuCelexRequest {
  kind: "eu_celex";
  celexNumber: string;
}

export type EliRequest =
  | IEliAccnAliasRequest
  | IEliCstCanonicalRequest
  | IEliFtCanonicalRequest
  | IEliRetsinfoClassificationAliasRequest
  | IEliCstPartialRequest
  | IEliRetsinfoClassificationPartialRequest
  | IEliEuCelexRequest;
export type EliRequestKinds = EliRequest["kind"];

export function assertNever(_: never): never {
  throw new Error("This should never happen! Unexpected: " + _);
}

export function buildEliRelativeUrl(req: EliRequest): string {
  switch (req.kind) {
    case "ft_canonical":
      return `eli/ft/${req.accn}`;
    case "accn_alias":
      return `eli/accn/${req.accn}`;
    case "cst_canonical":
      return `eli/${req.pubMedia}/${req.year}/${req.number}`;
    case "classification_alias":
      return `eli/${req.classification}/${req.docType}/${req.year}/${req.month}/${req.day}/${req.number}`;
    case "eu_celex":
      return `eli/eu/${req.celexNumber}`;
    case "cst_partial":
    case "classification_partial":
      throw new Error(`Building partial URL is not supported for partial request kinds. ${req.kind} not supported.`);
    default:
      return assertNever(req);
  }
}

export function hasEliRequestChanged(previousRequest: EliRequest, request: EliRequest): boolean {
  // This comparison works as long as ALL keys in the two objects are simple value types or arrays (or nested arrays of simple types)
  // We know that object of type EliRequest should be flat objects with properties of simple types
  // Just note that as soon as "toString" hits an object it is turned into the string representation "[object Object]" and NOT compared properly
  return Object.entries(previousRequest).toString() !== Object.entries(request).toString();
}

//TODO: is there a way to use the union EliRequest to create this intersection type?
type AllEliRequestParams = IEliAccnAliasRequest & IEliCstCanonicalRequest & IEliFtCanonicalRequest & IEliRetsinfoClassificationAliasRequest;
type AllEliPartialRequestParams = IEliCstPartialRequest & IEliRetsinfoClassificationPartialRequest & IEliEuCelexRequest;

type AllEliParamsExceptKind = Required<Omit<AllEliRequestParams & AllEliPartialRequestParams, "kind">>;

export const EliUrlParamDefinitions: Record<keyof AllEliParamsExceptKind, string> = {
  accn: ":accn([a-zA-Z0-9]{5,})",
  pubMedia: ":pubMedia(lta|ltb|ltc|mt|retsinfo|ft|fob)",
  year: ":year(1[6789]\\d\\d|2[01]\\d\\d)",
  number: ":number([0-9]+)",
  classification: ":classification(regel|afgoerelse)",
  docType: ":docType",
  month: ":month([1-9]|0[1-9]|1[012])",
  day: ":day([1-9]|0[1-9]|[12][0-9]|3[01])",
  celexNumber: ":celexNumber([a-zA-Z0-9]{8,})",
};

const { accn, pubMedia, year, number, classification, docType, month, day, celexNumber } = EliUrlParamDefinitions;

const singleDocumentBaseEliTemplates: [string, EliRequestKinds][] = [
  [`/eli/${pubMedia}/${year}/${number}`, "cst_canonical"],
  [`/eli/accn/${accn}`, "accn_alias"],
  [`/eli/ft/${accn}`, "ft_canonical"],
  [`/eli/${classification}/${docType}/${year}/${month}/${day}/${number}`, "classification_alias"],
];

export const singleDocumentEliTemplates: [string, EliRequestKinds][] = singleDocumentBaseEliTemplates.flatMap(([templateBase, kind]) =>
  ["", "/html", "/dan", "/dan/html"].map(relativePath => [`${templateBase}${relativePath}`, kind] as [string, EliRequestKinds])
);

export const singleDocumentEliTemplatesRawHtml: [string, EliRequestKinds][] = singleDocumentBaseEliTemplates.flatMap(([templateBase, kind]) =>
  ["/rawhtml"].map(relativePath => [`${templateBase}${relativePath}`, kind] as [string, EliRequestKinds])
);

export const multiDocumentEliTemplates: [string, EliRequestKinds][] = [
  [`/eli/${pubMedia}/${year}`, "cst_partial"],
  [`/eli/${classification}/${docType}/${year}/${month}?/${day}?`, "classification_partial"],
  [`/eli/eu/${celexNumber}`, "eu_celex"],
];
