import { createStore, combineReducers, applyMiddleware } from "redux";
import documentSearch from "./documents/documentSearchReducer";
import documentDetails from "./documentDetails/documentDetailsReducer";
import relatedDocuments from "./relatedDocuments/relatedDocumentsReducer";
import ressorter from "./ressorter/ressortReducer";
import documentClassifications from "./documentClassification/documentClassificationReducer";
import maintenanceMessagesReducer from "./maintenance/maintenanceMessagesReducer";
import notes from "./notes/notesReducer";
import eliDocumentation from "./eli/eliDocumentationReducer";
import eliText from "./eli/eliTextReducer";
import eliLists from "./eli/eliListsReducer";
import texts from "./texts/textsReducer";
import footer from "./footer/footerReducer";
import faqAreas from "./faq/faqReducer";
import eliRoutingData from "./eli/eliRoutingDataReducer";
import tags from "./tags/tagsReducer";
import filter from "./filterReducer";
import extremeSearch from "./extremeSearch/extremeSearchReducer";
import documentTimeline from "./documentTimeline/documentTimelineReducer";
import { compose } from "redux";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  relatedDocuments,
  documentDetails,
  documentSearch,
  ressorter,
  documentClassifications,
  maintenanceMessagesReducer,
  eliDocumentation,
  eliText,
  eliLists,
  texts,
  footer,
  faqAreas,
  eliRoutingData,
  notes,
  tags,
  filter,
  extremeSearch,
  documentTimeline,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...[thunk]), (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f)
  );

  return store;
}
