import { createAction, ActionsUnion } from "./actionHelper";
import { Dispatch } from "redux";

const SET_LAW_REGISTRY = "SET_LAW_REGISTRY";
const SET_MYNDIGHED = "SET_MYNDIGHED";
const SET_DOC_CLASSIFICATION = "SET_DOC_CLASSIFICATION";
const SET_HISTORICAL = "SET_HISTORICAL";
const SET_YEAR = "SET_YEAR";
const SET_RESSORT = "SET_RESSORT";

const receiveFilterState = (actionConstant: string, isOpen: boolean) => createAction(actionConstant, isOpen);

const actions = { receiveFilterState };
type FilterActions = ActionsUnion<typeof actions>;

const setLawRegistryIsOpen = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_LAW_REGISTRY, isOpen));
};
const setMyndighedIsOpen = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_MYNDIGHED, isOpen));
};
const setDocumentClassificationIsOpen = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_DOC_CLASSIFICATION, isOpen));
};
const setHistorialIsOpen = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_HISTORICAL, isOpen));
};
const setYearIsOpen = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_YEAR, isOpen));
};
const setRessortIsOpen = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_RESSORT, isOpen));
};
const setAllFilters = (isOpen: boolean) => async (dispatch: Dispatch<FilterActions>) => {
  dispatch(receiveFilterState(SET_LAW_REGISTRY, isOpen));
  dispatch(receiveFilterState(SET_MYNDIGHED, isOpen));
  dispatch(receiveFilterState(SET_DOC_CLASSIFICATION, isOpen));
  dispatch(receiveFilterState(SET_HISTORICAL, isOpen));
  dispatch(receiveFilterState(SET_YEAR, isOpen));
  dispatch(receiveFilterState(SET_RESSORT, isOpen));
};

export interface IFilterReducerActions {
  setLawRegistryIsOpen: (isOpen: boolean) => void;
  setMyndighedIsOpen: (isOpen: boolean) => void;
  setDocumentClassificationIsOpen: (isOpen: boolean) => void;
  setHistorialIsOpen: (isOpen: boolean) => void;
  setYearIsOpen: (isOpen: boolean) => void;
  setRessortIsOpen: (isOpen: boolean) => void;
  setAllFilters: (isOpen: boolean) => void;
}

export const FilterReducerActions = {
  setLawRegistryIsOpen,
  setMyndighedIsOpen,
  setDocumentClassificationIsOpen,
  setHistorialIsOpen,
  setYearIsOpen,
  setRessortIsOpen,
  setAllFilters,
};

export interface IFilterState {
  lawRegistryFilterIsOpen: boolean;
  myndighedFilterIsOpen: boolean;
  documentClassificationFilterIsOpen: boolean;
  historialFilterIsOpen: boolean;
  yearFilterIsOpen: boolean;
  ressortFilterIsOpen: boolean;
}

const defaultState: IFilterState = {
  lawRegistryFilterIsOpen: false,
  myndighedFilterIsOpen: false,
  documentClassificationFilterIsOpen: true,
  historialFilterIsOpen: true,
  yearFilterIsOpen: true,
  ressortFilterIsOpen: true,
};

export default (state: IFilterState = defaultState, action: FilterActions): IFilterState => {
  switch (action.type) {
    case SET_LAW_REGISTRY: {
      return {
        ...state,
        lawRegistryFilterIsOpen: action.payload,
      };
    }
    case SET_MYNDIGHED: {
      return {
        ...state,
        myndighedFilterIsOpen: action.payload,
      };
    }
    case SET_DOC_CLASSIFICATION: {
      return {
        ...state,
        documentClassificationFilterIsOpen: action.payload,
      };
    }
    case SET_HISTORICAL: {
      return {
        ...state,
        historialFilterIsOpen: action.payload,
      };
    }
    case SET_YEAR: {
      return {
        ...state,
        yearFilterIsOpen: action.payload,
      };
    }
    case SET_RESSORT: {
      return {
        ...state,
        ressortFilterIsOpen: action.payload,
      };
    }
    default:
      return state;
  }
};
