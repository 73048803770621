import { createAction, ActionsUnion } from "../actionHelper";
import { ITagSectionList } from "../../models/ITagSectionList";
import { Dispatch } from "redux";
import { AppState } from "..";
import { apiGET } from "../api";
import { ITag } from "../../models/ITag";

const RECEIVE_TAGS = "RECEIVE_TAGS";

const receiveTags = (tagResultDto: ITag[]) => createAction(RECEIVE_TAGS, tagResultDto);
const actions = { receiveTags };
type TagActions = ActionsUnion<typeof actions>;

const getTags = () => async (dispatch: Dispatch<TagActions>, getState: () => AppState) => {
  const { tags } = getState();
  if (tags.lawRegistry.length > 0) {
    return;
  }
  const lawRegistryTags = await apiGET<ITag[]>("api/lawregistry");
  dispatch(receiveTags(lawRegistryTags));
};

export interface ITagsActions {
  getTags: () => void;
}

export const TagActions: ITagsActions = {
  getTags,
};

const defaultState: ITagSectionList = {
  lawRegistry: [],
};

export default (state: ITagSectionList = defaultState, action: TagActions): ITagSectionList => {
  switch (action.type) {
    case RECEIVE_TAGS: {
      return {
        ...state,
        lawRegistry: action.payload,
      };
    }
    default:
      return state;
  }
};
