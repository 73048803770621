import { createAction, ActionsUnion } from "../actionHelper";
import { IRelatedDocuments } from "../../models/IRelatedDocuments";
import { apiGET } from "../api";
import { AppState } from "..";
import { Dispatch } from "redux";
import { IDocumentLinkGroupDocumentReferences } from "../../models/IDocumentLinkGroup";
import { IDocumentReference } from "../../models/IDocumentReference";

// Action Constants
const RECEIVE_RELATED_DOCUMENTS = "RECEIVE_RELATED_DOCUMENTS";
const RECEIVE_LINK_GROUP_DOCUMENTS = "RECEIVE_LINK_GROUP_DOCUMENTS";

// Action creators
const receiveRelatedDocuments = (relatedDocuments: IRelatedDocuments) => createAction(RECEIVE_RELATED_DOCUMENTS, relatedDocuments);
const receiveLinkGroupDocuments = (docRefs: IDocumentLinkGroupDocumentReferences) => createAction(RECEIVE_LINK_GROUP_DOCUMENTS, docRefs);

const actions = { receiveRelatedDocuments, receiveLinkGroupDocuments };
type RelatedDocumentsActions = ActionsUnion<typeof actions>;

const defaultState: IRelatedDocumentsState = {
  relatedDocumentsMap: {},
};

export const getRelatedDocuments = (documentId: number, includingReferencesDocuments: boolean) => async (
  dispatch: Dispatch<RelatedDocumentsActions>,
  getState: () => AppState
) => {
  const { relatedDocuments } = getState();
  if (relatedDocuments.relatedDocumentsMap[documentId]) {
    return relatedDocuments.relatedDocumentsMap[documentId];
  }

  const result = await apiGET<IRelatedDocuments>("api/document/" + documentId + "/references/" + (includingReferencesDocuments ? "1" : "0"));
  dispatch(receiveRelatedDocuments(result));

  return undefined;
};

export const getLinkGroupDocuments = (documentId: number, linkType: number, data: string) => async (dispatch: Dispatch<RelatedDocumentsActions>) => {
  const url = "api/document/documentLinks/" + linkType.toString() + "/" + data;
  const result = await apiGET<IDocumentReference[]>(url);
  const temp: IDocumentLinkGroupDocumentReferences = {
    documentId,
    data,
    type: linkType,
    references: result,
  };
  dispatch(receiveLinkGroupDocuments(temp));
};

// Action exports
export interface IRelatedDocumentsActions {
  getRelatedDocuments: (documentId: number, includingReferencesDocuments: boolean) => void;
  getLinkGroupDocuments: (documentId: number, linkType: number, data: string, header: string) => void;
}

export const RelatedDocumentsActions: IRelatedDocumentsActions = {
  getRelatedDocuments,
  getLinkGroupDocuments,
};

export interface IRelatedDocumentsState {
  relatedDocumentsMap: { [key: number]: IRelatedDocuments | undefined };
}

export default (state: IRelatedDocumentsState = defaultState, action: RelatedDocumentsActions): IRelatedDocumentsState => {
  switch (action.type) {
    case RECEIVE_RELATED_DOCUMENTS: {
      state.relatedDocumentsMap = { ...state.relatedDocumentsMap, [action.payload.documentId]: action.payload };
      return {
        ...state,
      };
    }
    case RECEIVE_LINK_GROUP_DOCUMENTS: {
      const newDocumentGroups = { ...state.relatedDocumentsMap }; //Use slice, to copy array without mutating original. https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns#inserting-and-removing-items-in-arrays
      const copy = newDocumentGroups[action.payload.documentId];
      const group = copy!.documentLinkGroups.find(group => {
        return group.data === action.payload.data && group.type === action.payload.type;
      });
      group!.references = action.payload.references;
      return {
        ...state,
        relatedDocumentsMap: newDocumentGroups,
      };
    }
    default:
      return state;
  }
};
