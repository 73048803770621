import React, { useEffect } from "react";
import "./_Layout.scss";
import packageJson from "../../package.json";
import { Container } from "reactstrap";
import { HeaderContainer } from "../containers/HeaderContainer";
import FooterContainer from "../containers/FooterContainer";
import { RouteComponentProps, withRouter } from "react-router";
import { DevelopmentSizeHelper } from "@lexdania/components";
import { setupApi } from "../store/api";
import MaintenanceContainer from "../containers/MaintenanceContainer";
import * as rdd from "react-device-detect";

const Layout: React.FC<RouteComponentProps<any>> = props => {
  useEffect(() => {
    const updateState = (errorCode: number) => {
      if (errorCode === 404) props.history.push("/notfound");
      else props.history.push("/error", { errorCode });
    };
    setupApi(updateState);
  }, [props.history]);

  useEffect(() => {
    if (rdd.isIOS) {
      var viewport = document.querySelector("meta[name=viewport]");
      if (viewport) {
        var content = viewport.getAttribute("content");
        // fixing unwanted auto zoom on ios devices when changing orientation from landscape to portrait
        // IOS >= 10 ignores maximum-scale due to accessibility issues, thus zoom is still possible
        viewport.setAttribute("content", content + ", maximum-scale=1");
      }
    }
  }, []); // empty array means it only run once

  return (
    <React.Fragment>
      <MaintenanceContainer />
      <div className="main-content-body">
        <div hidden={true}>{"lexdania/components version: " + packageJson.dependencies["@lexdania/components"]}</div>
        {process.env.REACT_IS_DEVELOPMENT && <DevelopmentSizeHelper />}
        <HeaderContainer />
        <Container className="content-area ">{props.children}</Container>
        <div className="layout-footer">
          <FooterContainer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);
