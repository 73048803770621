import { createAction, IActionWithPayload } from "../actionHelper";
import { apiGET } from "../api";
import { IRessort } from "../../models/IRessort";
import { AppState } from "..";

const RECEIVE_RESSORTER = "RECEIVE_RESSORTER";
const RECEIVE_FOB_RESSORTER = "RECEIVE_FOB_RESSORTER";

export interface IRessortReducerState {
  ministryRessorter: IRessort[];
  fobRessorter: IRessort[];
}
// API
const GETRessorter = () => apiGET<IRessort[]>("api/ressort");
const GETFobRessorter = () => apiGET<IRessort[]>("api/ressort/fob");

const receiveRessorter = (ressorter: IRessort[]) => createAction(RECEIVE_RESSORTER, ressorter);
const receiveFobRessorter = (fobRessorter: IRessort[]) => createAction(RECEIVE_FOB_RESSORTER, fobRessorter);

const getRessorter = () => async (dispatch: any, getState: () => AppState) => {
  const { ressorter } = getState();
  if (ressorter.ministryRessorter.length === 0) {
    const ressortResult = await GETRessorter();
    dispatch(receiveRessorter(ressortResult));
  }
};

const getFobRessorter = () => async (dispatch: any, getState: () => AppState) => {
  const { ressorter } = getState();
  if (ressorter.fobRessorter.length === 0) {
    const fobRessortResult = await GETFobRessorter();
    dispatch(receiveFobRessorter(fobRessortResult));
  }
};

// Actions Interface
export interface IRessortActions {
  getRessorter: () => void;
  getFobRessorter: () => void;
}

export const RessortActions: IRessortActions = {
  getRessorter,
  getFobRessorter,
};

const defaultState: IRessortReducerState = {
  ministryRessorter: [],
  fobRessorter: [],
};

export default (state: IRessortReducerState = defaultState, action: IActionWithPayload<string, IRessort[]>): IRessortReducerState => {
  switch (action.type) {
    case RECEIVE_RESSORTER:
      return {
        ...state,
        ministryRessorter: action.payload,
      };

    case RECEIVE_FOB_RESSORTER:
      return {
        ...state,
        fobRessorter: action.payload,
      };
    default:
      return state;
  }
};
