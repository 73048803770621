import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "..";
import { Dispatch } from "redux";

function rehydrateDocumentationText(exampleText: string) {
  return exampleText.replace(/https?:\/\/www\.retsinformation\.dk\S*/gi, '<a href="$&">$&</a>').replace(/\n/g, "<br />");
}

function metadataArrayToMap(array: IEliMetadataDocumentation[]): Map<string, IEliMetadataDocumentation[]> {
  const map = new Map<string, IEliMetadataDocumentation[]>();
  array.forEach(d => {
    const collection = map.get(d.parent);
    if (!collection) {
      map.set(d.parent, [d]);
    } else {
      collection.push(d);
    }
  });
  return map;
}

// Action Constants
enum EliActionType {
  RECEIVE_ELI_DOCUMENTATION = "RECEIVE_ELI_DOCUMENTATION",
}

// Action creators
const receiveEliDocumentation = (documentation: IEliDocumentationState) => createAction(EliActionType.RECEIVE_ELI_DOCUMENTATION, documentation);
const actions = { receiveEliDocumentation };
type EliReduxActions = ActionsUnion<typeof actions>;

const getEliDocumentation = () => async (dispatch: Dispatch<EliReduxActions>, getState: () => AppState) => {
  const { eliDocumentation } = getState();
  if (eliDocumentation === defaultState) {
    const documentation = await Promise.all([
      apiGET<IUriTemplateDocumentation[]>("/static/eli-technical-documentation.json"),
      apiGET<IUriTemplateParameterDocumentation[]>("/static/eli-parameters-documentation.json"),
      apiGET<(IEliMetadataDocumentation & { example: string })[]>("/static/eli-metadata-documentation.json"),
      apiGET<IEliUriTemplateGroup[]>("api/eli/documentation/uri-templates"),
      apiGET<{ key: string; types: { id: string; type: string }[] }[]>("api/eli/documentation/metadata-types"),
    ]);

    documentation[0].forEach(td => {
      td.exampleUris = td.exampleUris.map(eu => rehydrateDocumentationText(eu));
      td.uriExplanations.forEach(ue => {
        ue.name = rehydrateDocumentationText(ue.name);
        ue.explanation = ue.explanation.map(e => rehydrateDocumentationText(e));
        return ue;
      });
    });

    documentation[1].forEach(pd => {
      pd.comment = rehydrateDocumentationText(pd.comment).replace(/\n/g, "<br />");
    });

    documentation[2].forEach(md => {
      md.description = rehydrateDocumentationText(md.description).replace(/\n/g, "<br />");

      const match = md.example.match(/(.*)\n(.*)/) || ["dummy", md.example];
      md.exampleValue = rehydrateDocumentationText(match[1]);
      md.exampleExplanation = rehydrateDocumentationText(match[2] || "");
    });

    const eliMetadataMap = metadataArrayToMap(documentation[2]);
    Array.from(eliMetadataMap.entries()).forEach(([key, mds]) => {
      const legislationType = documentation[4].find(lt => lt.key.toLowerCase() === key.toLowerCase());
      if (legislationType) {
        mds.forEach(md => {
          const typeObj = legislationType.types.find(t => t.id === md.id);
          md.type = typeObj ? typeObj.type : "";
        });
      }
    });

    const result: IEliDocumentationState = {
      uriTemplates: documentation[0],
      uriTemplateParameters: documentation[1],
      eliMetadata: eliMetadataMap,
      registeredUriTemplateGroups: documentation[3],
    };

    dispatch(receiveEliDocumentation(result));
  }
};

// Action exports
export interface IEliDocumentationActions {
  getEliDocumentation: () => void;
}

export const EliDocumentationActions = {
  getEliDocumentation,
};

// Reducer and state
export interface IEliDocumentationState {
  uriTemplates: IUriTemplateDocumentation[];
  uriTemplateParameters: IUriTemplateParameterDocumentation[];
  eliMetadata: Map<string, IEliMetadataDocumentation[]>;
  registeredUriTemplateGroups: IEliUriTemplateGroup[];
}

export interface IUriTemplateDocumentation {
  id: string;
  description: string;
  exampleUris: string[];
  uriExplanations: { name: string; explanation: string[]; uri?: string }[];
}

export interface IUriTemplateParameterDocumentation {
  id: string;
  eliComponentName: number;
  valueFormat?: string;
  comment: string;
}

export interface IEliMetadataDocumentation {
  id: string;
  parent: string;
  exampleValue: string;
  exampleExplanation: string;
  description: string;
  type?: string;
}

export interface IEliUriTemplateGroup {
  uriTemplateType: string;
  name: string;
  groupName: string;
  templates: IEliUriTemplate[];
}

export interface IEliUriTemplate {
  uriTemplateType: string;
  name: string;
  legalResourceUri: string;
}

const defaultState: IEliDocumentationState = {
  uriTemplates: [],
  uriTemplateParameters: [],
  eliMetadata: new Map<string, IEliMetadataDocumentation[]>(),
  registeredUriTemplateGroups: [],
};

export default (state: IEliDocumentationState = defaultState, action: EliReduxActions): IEliDocumentationState => {
  const type = action.type;
  switch (type) {
    case EliActionType.RECEIVE_ELI_DOCUMENTATION:
      return action.payload;
    default:
      return state;
  }
};
