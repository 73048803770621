import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "../../store";
import { IFooterLink } from "@lexdania/components";

export interface IFooterState {
  externalLinks: IExternalLinks;
  isFooterVisible: boolean;
}
export interface IExternalLinks {
  listLinks: IFooterLink[];
  wasLink: IFooterLink;
}

// Actions
enum TextActionEnum {
  RECEIVE_EXTERNALLINKS = "RECEIVE_EXTERNALLINKS",
  SHOW_FOOTER = "SHOW_FOOTER",
  HIDE_FOOTER = "HIDE_FOOTER",
}

//Action creators
const receiveExternalLinks = (links: IExternalLinks) => createAction(TextActionEnum.RECEIVE_EXTERNALLINKS, links);
const showFooter = () => createAction(TextActionEnum.SHOW_FOOTER);
const hideFooter = () => createAction(TextActionEnum.HIDE_FOOTER);
const Actions = { receiveExternalLinks, showFooter, hideFooter };
type FooterActions = ActionsUnion<typeof Actions>;

// Bound Action creators / Fetch
const getExternalLinks = () => async (dispatch: any, getState: () => AppState) => {
  const links = getState().footer.externalLinks;
  if (links && links !== null && links.listLinks.length > 0) {
    return;
  }
  const result = await apiGET<IExternalLinks>("static/externalLinks.json");
  dispatch(receiveExternalLinks(result));
};

export interface IFooterReducerActions {
  getExternalLinks(): Promise<void>;
  showFooter(): Promise<void>;
  hideFooter(): Promise<void>;
}

export const FooterReducerActions = {
  getExternalLinks,
  showFooter,
  hideFooter,
};

//Reducer
const defaultState: IFooterState = {
  externalLinks: { listLinks: [], wasLink: { url: "", text: "" } },
  isFooterVisible: true,
};

export default (state = defaultState, action: FooterActions): IFooterState => {
  switch (action.type) {
    case TextActionEnum.RECEIVE_EXTERNALLINKS: {
      return { ...state, externalLinks: action.payload };
    }
    case TextActionEnum.SHOW_FOOTER: {
      return { ...state, isFooterVisible: true };
    }
    case TextActionEnum.HIDE_FOOTER: {
      return { ...state, isFooterVisible: false };
    }
    default:
      return state;
  }
};
