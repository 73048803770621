import { createAction, ActionsUnion } from "../actionHelper";
import { AppState } from "..";
import { Dispatch } from "redux";
import { apiGET } from "../api";
import { TreeItem } from "react-awesome-query-builder";
import { ICaseHistoryStatus } from "../../models/ICaseHistoryStatus";

// Action Constants
const RECEIVE_FOBTAGS = "RECEIVE_FOBTAGS";
const RECEIVE_LAWREGISTERS = "RECEIVE_LAWREGISTERS";
const RECEIVE_CASEHISTORYSTATUS = "RECEIVE_CASEHISTORYSTATUS";
const RECEIVE_TOGGLE_GRID_VIEW_ACTIVE = "RECEIVE_TOGGLE_EXTREMESEARCH_GRID_VIEW_ACTIVE";

export interface IExtremeSearchState {
  tags: string;
  lawRegisters: string;
  caseHistoryStatus: ICaseHistoryStatus[];
  isGridViewActive: boolean;
}

// Action creators
const receiveTags = (tags: string) => createAction(RECEIVE_FOBTAGS, tags);
const receiveLawRegisters = (lawRegisters: string) => createAction(RECEIVE_LAWREGISTERS, lawRegisters);
const recieveCaseHistoryStatus = (status: ICaseHistoryStatus[]) => createAction(RECEIVE_CASEHISTORYSTATUS, status);
const receiveToggleGridViewActive = () => createAction(RECEIVE_TOGGLE_GRID_VIEW_ACTIVE);

const actions = { receiveTags, receiveLawRegisters, recieveCaseHistoryStatus, receiveToggleGridViewActive };
type ExtremeSearchActions = ActionsUnion<typeof actions>;

const getFobTags = () => async (dispatch: Dispatch<ExtremeSearchActions>, getState: () => AppState) => {
  const tags = getState().extremeSearch.tags;
  if (tags && tags !== null) {
    return;
  }

  const result = await apiGET<TreeItem[]>("api/extremesearch/GetFobTags");
  const stringifiedTreeItems = JSON.stringify(result); // For some reason the nested object "loses it's children" when added to global state. Therefore we store at as string, and JSON.parse it back when needed.
  dispatch(receiveTags(stringifiedTreeItems));
};

const getLawRegisters = () => async (dispatch: Dispatch<ExtremeSearchActions>, getState: () => AppState) => {
  const lawRegisters = getState().extremeSearch.lawRegisters;
  if (lawRegisters && lawRegisters !== null && lawRegisters.length > 0) {
    return;
  }

  const result = await apiGET<TreeItem[]>("api/extremesearch/GetLawRegisters");
  const stringifiedTreeItems = JSON.stringify(result); // For some reason the nested object "loses it's children" when added to global state. Therefore we store at as string, and JSON.parse it back when needed.
  dispatch(receiveLawRegisters(stringifiedTreeItems));
};

const getCaseHistoryStatus = () => async (dispatch: Dispatch<ExtremeSearchActions>, getState: () => AppState) => {
  const caseHistoryStatus = getState().extremeSearch.caseHistoryStatus;
  if (caseHistoryStatus && caseHistoryStatus.length > 0) return;

  const result = await apiGET<ICaseHistoryStatus[]>("/api/extremesearch/getcasehistorystatus");
  dispatch(recieveCaseHistoryStatus(result));
};

const toggleGridViewActive = () => (dispatch: Dispatch<ExtremeSearchActions>) => {
  dispatch(receiveToggleGridViewActive());
};

// Action Exports
export interface IExtremeSearchActions {
  getFobTags(): Promise<void>;
  getLawRegisters(): Promise<void>;
  getCaseHistoryStatus(): Promise<void>;
  toggleGridViewActive(): Promise<void>;
}

export const ExtremeSearchActions = {
  getFobTags,
  getLawRegisters,
  getCaseHistoryStatus,
  toggleGridViewActive,
};

const defaultState: IExtremeSearchState = {
  tags: "",
  lawRegisters: "",
  caseHistoryStatus: [],
  isGridViewActive: false,
};

export default (state = defaultState, action: ExtremeSearchActions): IExtremeSearchState => {
  switch (action.type) {
    case RECEIVE_FOBTAGS: {
      return { ...state, tags: action.payload };
    }

    case RECEIVE_LAWREGISTERS: {
      return { ...state, lawRegisters: action.payload };
    }

    case RECEIVE_CASEHISTORYSTATUS: {
      return { ...state, caseHistoryStatus: action.payload };
    }

    case RECEIVE_TOGGLE_GRID_VIEW_ACTIVE: {
      return {
        ...state,
        isGridViewActive: !state.isGridViewActive,
      };
    }

    default:
      return state;
  }
};
