import * as React from "react";
import { MaintenanceBanner } from "@lexdania/components";
import { connect } from "react-redux";
import { AppState } from "../store";
import { IMaintenanceMessagesActions, MaintenanceMessagesActions, BrowserText } from "../store/maintenance/maintenanceMessagesReducer";
import { bindActionCreators } from "redux";
import { IMaintenanceMessage } from "../models/IMaintenanceMessage";
import "./MaintenanceContainer.scss";

interface IProps extends IMaintenanceMessagesActions {
  messages: IMaintenanceMessage[];
  isMessagesSeen: boolean;
  browserTextState: number;
}

class MaintenanceContainer extends React.Component<IProps, {}> {
  public componentDidMount() {
    this.props.getMaintenanceMessages();
  }

  public onOkClick = () => {
    this.props.setOkClicked();
  };

  public onOkClickSupportText = () => {
    this.props.setOkClickedBrowserText();
  };

  showMessages() {
    const { messages, isMessagesSeen } = this.props;

    if (messages && messages.length <= 0) {
      return false;
    }

    if (isMessagesSeen) {
      return false;
    }

    return true;
  }

  public render() {
    const { messages, browserTextState } = this.props;

    return (
      <div>
        {this.showMessages() && (
          <MaintenanceBanner
            onOkClicked={() => {
              this.props.setOkClicked();
            }}
          >
            {messages.map((m, i) => (
              <div key={i}>
                <h1>{m.title}</h1>
                <pre className="maintenance-banner-pre">{m.content}</pre>
              </div>
            ))}
          </MaintenanceBanner>
        )}
        {browserTextState === BrowserText.Active && (
          <MaintenanceBanner onOkClicked={this.onOkClickSupportText}>
            <div key="browser-support">
              <h1>Browser understøttelse</h1>
              <pre className="maintenance-banner-pre">
                Der er ikke fuld undetøttelse af denne browser. Benyt venligst Google Chrome, Mozilla FireFox, Safari eller den nyeste Microsoft Edge.
              </pre>
            </div>
          </MaintenanceBanner>
        )}
      </div>
    );
  }
}

export default connect(
  ({ maintenanceMessagesReducer }: AppState, ownProps: IProps) => ({
    ...ownProps,
    ...{
      messages: maintenanceMessagesReducer.messages,
      isMessagesSeen: maintenanceMessagesReducer.isMessagesSeen,
      browserTextState: maintenanceMessagesReducer.browserTextState,
    },
  }),
  dispatch =>
    bindActionCreators(
      {
        ...MaintenanceMessagesActions,
      },
      dispatch
    )
)(MaintenanceContainer);
