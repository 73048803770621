import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { IDocumentClassification } from "../../models/IDocumentClassification";
import { AppState } from "..";

// Action Constants
const RECEIVE_DOCUMENT_CLASSIFICATIONS = "RECEIVE_DOCUMENT_CLASSIFICATIONS";
const REQUEST_DOCUMENT_CLASSIFICATIONS = "REQUEST_DOCUMENT_CLASSIFICATIONS";

// Action creators
const receiveDocumentClassifications = (databases: IDocumentClassification[]) => createAction(RECEIVE_DOCUMENT_CLASSIFICATIONS, databases);
const requestDocumentClassifications = () => createAction(REQUEST_DOCUMENT_CLASSIFICATIONS);

const actions = { receiveDocumentClassifications, requestDocumentClassifications };
type DocumentClassificationActions = ActionsUnion<typeof actions>;

const getDocumentClassification = () => async (dispatch: any, getState: () => AppState) => {
  const { documentClassifications } = getState();
  if (documentClassifications.documentClassifications.length === 0) {
    if (!documentClassifications.isLoading) {
      dispatch(requestDocumentClassifications());
      const databaseResult = await apiGET<IDocumentClassification[]>("api/documentClassificationfilter");
      dispatch(receiveDocumentClassifications(databaseResult));
    }
  }
};

export interface IDocumentClassificationState {
  documentClassifications: IDocumentClassification[];
  isLoading: boolean;
}

// Action Exports
export interface IDocumentClassificationActions {
  getDocumentClassification: () => void;
}

export const DocumentClassificationActions: IDocumentClassificationActions = {
  getDocumentClassification,
};

const defaultState: IDocumentClassificationState = {
  documentClassifications: [],
  isLoading: false,
};

export default (state: IDocumentClassificationState = defaultState, action: DocumentClassificationActions): IDocumentClassificationState => {
  switch (action.type) {
    case RECEIVE_DOCUMENT_CLASSIFICATIONS: {
      return {
        ...state,
        documentClassifications: action.payload,
        isLoading: false,
      };
    }
    case REQUEST_DOCUMENT_CLASSIFICATIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};
