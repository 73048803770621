import { createAction, ActionsUnion } from "../actionHelper";
import { ITimelineDocument } from "../../models/ITimelineDocument";
import { apiGET } from "../api";
import { Dispatch } from "redux";
import { GeografiskDaekning } from "../../models/GeografiskDaekningEnum";

// Action Constants

const RECEIVE_DOCUMENT_TIMELINE = "RECEIVE_DOCUMENT_TIMELINE";

// Action creators
const receiveTimeline = (timeline: IDocumentTimelineState) => createAction(RECEIVE_DOCUMENT_TIMELINE, timeline);

const actions = { receiveTimeline };
type DocumentTimelineActions = ActionsUnion<typeof actions>;

const defaultState: IDocumentTimelineState = {
  timelineDocuments: null,
  documentId: null,
};

export const getTimeline = (documentId: number, documentTypeId: string, geografiskDaekningId: number) => async (
  dispatch: Dispatch<DocumentTimelineActions>
) => {
  if (
    ["10", "30"].includes(documentTypeId) && // Lov, lovbekendtgørelse
    (geografiskDaekningId === GeografiskDaekning.IkkeGronlandOgFaeroerne || geografiskDaekningId === GeografiskDaekning.HeleRigsfaellesskabet)
  ) {
    dispatch(receiveTimeline({ timelineDocuments: [], documentId: null }));

    const url = "api/document/" + documentId.toString() + "/timeline";
    const result = await apiGET<ITimelineDocument[]>(url);

    dispatch(receiveTimeline({ timelineDocuments: result, documentId: documentId }));
  } else {
    dispatch(receiveTimeline({ timelineDocuments: null, documentId: null }));
  }
};

// Action exports
export interface IDocumentTimelineActions {
  getTimeline: (documentId: number, documentTypeId: string, geografiskDaekningId: number) => void;
}

export const DocumentTimelineActions: IDocumentTimelineActions = {
  getTimeline,
};

export interface IDocumentTimelineState {
  timelineDocuments: ITimelineDocument[] | null; // null value if no timeline is available (disabled or not relevant for current document type)
  documentId: number | null;
}

export default (state: IDocumentTimelineState = defaultState, action: DocumentTimelineActions): IDocumentTimelineState => {
  switch (action.type) {
    case RECEIVE_DOCUMENT_TIMELINE: {
      state.timelineDocuments = action.payload.timelineDocuments;
      state.documentId = action.payload.documentId;
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
