import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { EliInfoLanguage } from "./eliDomain";

// Action Constants
export enum TextActionEnum {
  RECEIVE_ELIINFO = "RECEIVE_ELIINFO",
  SET_ELIINFOLANGUAGE = "SET_ELIINFOLANGUAGE",
}

// Action creators
const receiveEliInfo = (da: string, en: string) => createAction(TextActionEnum.RECEIVE_ELIINFO, { da, en });
const setEliInfoLanguage = (language: EliInfoLanguage) => createAction(TextActionEnum.SET_ELIINFOLANGUAGE, language);

const actions = { receiveEliInfo, setEliInfoLanguage };
type EliTextsActions = ActionsUnion<typeof actions>;

const getEliInfoTexts = () => async (dispatch: any, getState: any) => {
  const { texts } = getState();
  if (texts && texts.da && texts.en) {
    return;
  }
  const [da, en] = await Promise.all([apiGET<string>("static/eliIntro.da.html"), apiGET<string>("static/eliIntro.en.html")]);
  dispatch(receiveEliInfo(da, en));
};

export interface IEliTextState extends Record<EliInfoLanguage, string> {
  selectedLanguage: EliInfoLanguage;
}

// Action exports
export interface IEliTextFetchers {
  getEliInfoTexts: () => void;
  setEliInfoLanguage: (locale: EliInfoLanguage) => void;
}

export const EliTextReducerActions = {
  getEliInfoTexts,
  setEliInfoLanguage,
};

const defaultState: IEliTextState = {
  da: "",
  en: "",
  selectedLanguage: "da",
};

export default (state: IEliTextState = defaultState, action: EliTextsActions): IEliTextState => {
  switch (action.type) {
    case TextActionEnum.RECEIVE_ELIINFO: {
      return {
        ...state,
        da: action.payload.da,
        en: action.payload.en,
      };
    }
    case TextActionEnum.SET_ELIINFOLANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    }
    default:
      return state;
  }
};
