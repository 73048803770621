import { createAction, ActionsUnion } from "../actionHelper";
import { apiGET } from "../api";
import { AppState } from "..";
import { Dispatch } from "redux";
import { IDocumentTypeUrlParameterDto } from "../../models/EliRoutingDataModels";

// Action Constants
enum EliRoutingDataActionType {
  RECEIVE_ELI_ROUTING_DATA = "RECEIVE_ELI_ROUTING_DATA",
}

// Action creators
const receiveEliRoutingData = (payload: IEliRoutingDataState) => createAction(EliRoutingDataActionType.RECEIVE_ELI_ROUTING_DATA, payload);

const actionCreatorsMap = { receiveEliRoutingData };
type EliRoutingActionsUnion = ActionsUnion<typeof actionCreatorsMap>;

const getEliRoutingData = () => async (dispatch: Dispatch<EliRoutingActionsUnion>, getState: () => AppState) => {
  const { eliRoutingData } = getState();
  if (eliRoutingData === defaultState) {
    const eliReouteData = await apiGET<IEliRoutingDataState>("api/eli/routing-data");
    dispatch(receiveEliRoutingData(eliReouteData));
  }
};

export interface IEliRoutingDataState {
  docTypeUrlParameterMap: { [P: string]: IDocumentTypeUrlParameterDto[] };
  publicationMediaUrlParameterMap: { [P: string]: number };
}

const defaultState: IEliRoutingDataState = {
  docTypeUrlParameterMap: {},
  publicationMediaUrlParameterMap: {},
};

// Action Exports
export interface IEliRoutingDataActions {
  getEliRoutingData: () => void;
}

export const EliRoutingDataActions: IEliRoutingDataActions = {
  getEliRoutingData,
};

export default (state: IEliRoutingDataState = defaultState, action: EliRoutingActionsUnion): IEliRoutingDataState => {
  switch (action.type) {
    case EliRoutingDataActionType.RECEIVE_ELI_ROUTING_DATA:
      return action.payload;
    default:
      return state;
  }
};
