import { createAction, ActionsUnion } from "../actionHelper";
import { AppState } from "..";
import { Dispatch } from "redux";
import { IFaqArea } from "@lexdania/components";
import { apiGET } from "../api";

// Action Constants
const RECEIVE_FAQ = "RECEIVE_FAQ";

// Action creators
const receiveFaq = (faq: IFaqArea[]) => createAction(RECEIVE_FAQ, faq);
const actions = { receiveFaq };
type FaqActions = ActionsUnion<typeof actions>;

const getFaq = () => async (dispatch: Dispatch<FaqActions>, getState: () => AppState) => {
  const { faqAreas } = getState();
  if (faqAreas.length > 0) {
    return;
  }

  const result = await apiGET<IFaqArea[]>("static/faq.json");
  dispatch(receiveFaq(result));
};

// Action Exports
export interface IFaqActions {
  getFaq: () => void;
}

export const FaqActions: IFaqActions = {
  getFaq,
};

const defaultstate: IFaqArea[] = [];

export default (state: IFaqArea[] = defaultstate, action: FaqActions): IFaqArea[] => {
  switch (action.type) {
    case RECEIVE_FAQ: {
      return action.payload;
    }
    default:
      return state;
  }
};
