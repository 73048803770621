import { ActionsUnion, createAction, IActionWithPayload } from "../actionHelper";
import { Dispatch } from "redux";
import { apiGET } from "../api";
import { IMaintenanceMessage } from "../../models/IMaintenanceMessage";

// Action Constants
const SET_OK_CLICKED = "SET_OK_CLICKED";
const GET_MAINTENANCE_MESSAGES = "GET_MAINTENANCE_MESSAGES";
const BROWSER_IS_NOT_SUPPORTED = "BROWSER_IS_NOT_SUPPORTED";
const SET_OK_CLICKED_BROWSER_TEXT = "SET_OK_CLICKED_BROWSER_TEXT";

export interface IMaintenanceMessagesState {
  messages: IMaintenanceMessage[];
  isMessagesSeen: boolean;
  browserTextState: number;
}

export enum BrowserText {
  Unknown = 0,
  Active = 1,
  Seen = 2,
}

// External action methods
const setOkClicked = () => createAction(SET_OK_CLICKED);
const browserIsNotSupported = () => createAction(BROWSER_IS_NOT_SUPPORTED);
const setOkClickedBrowserText = () => createAction(SET_OK_CLICKED_BROWSER_TEXT);

const getMaintenanceMessages = () => async (dispatch: Dispatch<LocalActions>) => {
  const messages = await apiGET<IMaintenanceMessage[]>("api/maintenance/messages");
  dispatch(setMaintenanceMessages(messages));
};

// External actions
export interface IMaintenanceMessagesActions {
  setOkClicked: () => void;
  getMaintenanceMessages: () => void;
  browserIsNotSupported: () => void;
  setOkClickedBrowserText: () => void;
}

export const MaintenanceMessagesActions: IMaintenanceMessagesActions = {
  setOkClicked: setOkClicked,
  getMaintenanceMessages: getMaintenanceMessages,
  browserIsNotSupported: browserIsNotSupported,
  setOkClickedBrowserText: setOkClickedBrowserText,
};

// Local actions
const setMaintenanceMessages = (messages: IMaintenanceMessage[]) => createAction(GET_MAINTENANCE_MESSAGES, messages);

const Actions = { setMaintenanceMessages };
type LocalActions = ActionsUnion<typeof Actions>;

const defaultState: IMaintenanceMessagesState = {
  messages: [],
  isMessagesSeen: false,
  browserTextState: BrowserText.Unknown,
};

export default (state: IMaintenanceMessagesState = defaultState, action: IActionWithPayload<string, IMaintenanceMessage[]>): IMaintenanceMessagesState => {
  switch (action.type) {
    case SET_OK_CLICKED:
      return { ...state, ...{ isMessagesSeen: true } };
    case GET_MAINTENANCE_MESSAGES:
      return { ...state, ...{ messages: action.payload } };
    case BROWSER_IS_NOT_SUPPORTED:
      const currentState = state.browserTextState;
      if (currentState === BrowserText.Seen) return { ...state };

      return { ...state, ...{ browserTextState: BrowserText.Active } };
    case SET_OK_CLICKED_BROWSER_TEXT:
      return { ...state, ...{ browserTextState: BrowserText.Seen } };
    default:
      return state;
  }
};
